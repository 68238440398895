import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import HeaderIllustration from "@Components/HeaderIllustration";
import SubMenu from "@Components/SubMenu";
import Accordion from "@Components/Accordion";
import AccordionItem from "@Components/AccordionItem";
import BlogOverview from "@Components/BlogOverview";
import BlogOverviewItem from "@Components/BlogOverviewItem";
import SplitViewPhoto from "@Components/SplitViewPhoto";
import Loading from "@Components/Loading";
import dispatchRerender from 'dispatchRerender';
import FormContext from "formContext";
import axios from "axios";

function InterviewPreparation({intl, match}) {
  const {locale} = intl;
  const {additionalData, setAdditionalData} = useContext(FormContext);
  const {interviewSettings} = additionalData;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [reinit, setReinit] = useState(false);

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      if (!window.__ROUTE_DATA__.interviewSettings || Object.keys(window.__ROUTE_DATA__.interviewSettings).length === 0) {
        setShouldRedirect(true);
      }
      delete window.__ROUTE_DATA__;
    }
    else {
      const getInterviewSettings = async () => {
        await axios.get(`${process.env.REACT_APP_API_PREFIX}/interview-settings?currentLanguage=${locale}`)
        .then(response => {
          if (!response.data.interviewSettings || Object.keys(response.data.interviewSettings).length === 0) {
            setShouldRedirect(true);
          }
          setAdditionalData(response.data);
          setReinit(true);
        });
      };
      getInterviewSettings();

    }
  }, []);

  useEffect(() => {
    if (reinit) dispatchRerender();
  }, [reinit]);

  if (shouldRedirect) {
    window.location.href = `${window.location.pathname}not-found`;
  }

  if (!interviewSettings || Object.keys(interviewSettings).length === 0) {
    return <Loading />;
  }

  const headerIllustrationImage = '/myrandstad-app/assets/image/interview-preparation/people.svg';

  const blogOverviewImages = [
    '/myrandstad-app/assets/image/interview-preparation/document.svg',
    '/myrandstad-app/assets/image/interview-preparation/person.svg',
    '/myrandstad-app/assets/image/interview-preparation/document2.svg',
  ];

  const splitViewPhotoTablet = '/myrandstad-app/assets/image/interview-preparation/standing-out-tablet.jpg';
  const splitViewPhotoDesktop = '/myrandstad-app/assets/image/interview-preparation/standing-out-desktop.jpg';

  const renderPageElements = () => {
    const renderableConfigs = [];
    if (!!interviewSettings) {

      // Render Header Component
      if (!!interviewSettings.header) {
        renderableConfigs.push (
          <HeaderIllustration title={interviewSettings.header.name} text={interviewSettings.header.main_text.value} image={headerIllustrationImage} />
        )
      }

      // Render Accordion Component
      if (!!interviewSettings.top_ten) {
        let questions = Array.from(Object.keys(interviewSettings.top_ten).filter(q => q.startsWith('question_'))) || [];

        renderableConfigs.push (
          <Accordion title={interviewSettings.top_ten.name} background="bg-variant-brand-senary">
            {questions.map((item, i) => (
              <AccordionItem index={i} title={interviewSettings.top_ten[item].name} text={interviewSettings.top_ten[item].text.value} />
            ))}
          </Accordion>
        )
      }

      // Render Blog Overview Component in Strong answer section
      if (!!interviewSettings.strong_answer) {
        let sections = Array.from(Object.keys(interviewSettings.strong_answer).filter(q => q.startsWith('section_'))) || [];

        renderableConfigs.push (
          <BlogOverview title={interviewSettings.strong_answer.name} subtitle={interviewSettings.strong_answer.main_text.value}>
            {sections.map((item, i) => (
              <BlogOverviewItem index={i} title={interviewSettings.strong_answer[item].name} text={interviewSettings.strong_answer[item].text.value} image={blogOverviewImages[i]} />
            ))}
          </BlogOverview>
        )
      }

      // Render Split View Component in Standing out section
      if (!!interviewSettings.standing_out) {
        renderableConfigs.push (
          <SplitViewPhoto title={interviewSettings.standing_out.name} text={interviewSettings.standing_out.text.value} background="bg-variant-brand-quaternary" imageTablet={splitViewPhotoTablet} imageDesktop={splitViewPhotoDesktop} />
        )
      }
    }

    return renderableConfigs;
  };

  return (
    <>
      <Helmet>
        <title>{interviewSettings.header.name} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className={`header bg-variant-brand-tertiary`}/>
      </Helmet>
      <SubMenu skipBreadcrumbs={true} />
      {renderPageElements()}
    </>
  )
}

export default injectIntl(InterviewPreparation);
